<template>
    <gmap-map
        ref="mapRef"
        :center="center"
        :zoom="zoom"
        map-type-id="terrain"
        :options="{
            scrollwheel: false
        }"
    >
        <slot name="markers" />
        <!-- :icon="marker.icon" -->
        <!-- :icon="kurwaMac()" -->

        <gmap-marker
            v-for="marker in markersPOI"
            :key="marker.id"
            :position="{
                lat: Number(marker.map_lat),
                lng: Number(marker.map_lng),
            }"
            :title="marker.title"
            :visible="checkIfItsInBound(marker)"
            icon="images/marker_poi_2.png"
            :zIndex="5"
            @click="marker.open = true"
        >
            <gmap-info-window
                :opened="marker.open"
                @closeclick="marker.open = false"
            >
                <div class="flex flex-col">
                    <router-link v-if="marker.client_id" :to="`/clients/${marker.client_uuid}/dashboard`" class="mb-4 font-semibold hover:text-red-500">
                        {{ marker.client_name }} {{ marker.client_surname }}
                    </router-link>
                    <p class="mb-4">
                        {{ marker.title }}
                    </p>
                    <p>
                        {{ marker.street }}<br>
                        {{ marker.city }} {{ marker.post_code }}
                    </p>
                    <p v-if="marker.description" class="border-t mt-3 pt-3">
                        {{ marker.description }}
                    </p>
                </div>
            </gmap-info-window>
        </gmap-marker>
        <gmap-marker
            v-for="marker in markers"
            :key="marker.uuid"
            :position="getMapLatLng(marker)"
            :title="marker.title"
            :visible="checkIfItsInBound(marker)"
            :icon="marker.icon"
            :zIndex="marker.icon === 'images/jogging.png' ? 4 : 3"
            @click="toggleInfoWindow(marker)"
        >
            <gmap-info-window
                :opened="marker.infoWindow.opened"
                @closeclick="marker.infoWindow.opened=false"
            >
                <div v-html="marker.infoWindow.content" />
            </gmap-info-window>
        </gmap-marker>
        <gmap-marker
            v-for="marker in markersClientsAddresses"
            :key="marker.uuid"
            :position="getMapLatLng(marker)"
            :title="marker.title || marker.client"
            :visible="checkIfItsInBound(marker)"
            :icon="getClientAddressIcon(marker.status)"
            :zIndex="marker.icon === 'images/jogging.png' ? 4 : 3"
            @click="marker.open = true"
        >
            <gmap-info-window
                :opened="marker.open"
                @closeclick="marker.open = false"
            >
                <div class="flex flex-col">
                    <p v-if="marker.status" class="text-gray-400 mb-2">
                        {{ $t(`map.${marker.status}`) }}
                    </p>
                    <router-link :to="`/clients/${marker.client_uuid}/dashboard`" class="mb-4 font-semibold hover:text-red-500">
                        {{ marker.client }}
                    </router-link>
                    <p v-if="marker.title" class="flex flex-col mb-4">
                        {{ marker.title }}
                    </p>
                    <p>
                        {{ marker.street }}<br>
                        {{ marker.city }} {{ marker.post_code }}
                    </p>
                </div>
            </gmap-info-window>
        </gmap-marker>
    </gmap-map>
</template>
<script>
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
import { gmapApi } from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
    installComponents: true,
    load:              {
        key:       'AIzaSyCNIRpGZdH8CTBizW-HcGlAhnO_8YHvRNg',
        libraries: 'places,drawing,callback=initMap',
        language:  'se',
    },
});

export default {
    props: {
        markersClientsAddresses: {
            type:    Array,
            default: () => [],
        },
        markersPOI: {
            type:    Array,
            default: () => [],
        },
        markers: {
            type:    Array,
            default: () => [],
        },
        center: {
            type:    Object,
            default: () => ({
                lat: 59.3286141,
                lng: 18.0626482,
            }),
        },
        zoom: {
            type:    Number,
            default: 10,
        },
    },

    data() {
        return {
            myMap:          null,
            myCircle:       null,
            markersPrimal:  [],
            markersPrimal2: [],
        };
    },

    watch: {
    },

    // watch: {
    //     markers: function (val) {
    //         console.count('watch');
    //         this.markersPrimal = JSON.parse(JSON.stringify(val));
    //         this.markersPrimal2 = JSON.parse(JSON.stringify(val));
    //     },
    // },

    mounted() {
        // this.$refs.mapRef.$mapPromise.then(map => {
        //     // this.markersPrimal = this.markers;
        //     this.myMap = map;
        //     const drawingManager = new window.google.maps.drawing.DrawingManager({
        //         drawingControl:        true,
        //         drawingControlOptions: {
        //             position:     window.google.maps.ControlPosition.TOP_CENTER,
        //             drawingModes: ['circle'],
        //         },
        //         circleOptions: {
        //             fillColor:    '#77b4ff',
        //             fillOpacity:  0.2,
        //             strokeColor:  '#7eb4ff',
        //             strokeWeight: 2,
        //             clickable:    false,
        //             editable:     true,
        //             zIndex:       9999,
        //         },
        //     });
        //     drawingManager.setMap(this.$refs.mapRef.$mapObject);

        //     const polyBtn = document.createElement('button');
        //     polyBtn.setAttribute('style', `
        //         background-color: rgb(255, 255, 255);
        //         cursor: pointer;
        //         padding: 5px 6px;
        //         margin: 5px 5px 5px -6px;
        //         border-radius: 2px;
        //         color: #000;
        //         font-size: 12px;
        //         font-weight: bold;
        //         height: 24px;
        //         width: 28px;
        //         border: none;
        //     `);
        //     polyBtn.innerHTML = `<span><svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-alt" role="img"
        //     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-trash-alt fa-w-14"><path fill="currentColor" d="M268
        //     416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41
        //     0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16
        //      16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368
        //      464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"
        //      class=""></path></svg></span>`;

        //     polyBtn.addEventListener('click', (() => () => {
        //         if (this.myCircle) {
        //             this.myCircle.setVisible(false);
        //             this.myCircle.setMap(null);
        //         }
        //     })());

        //     map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(polyBtn);

        //     window.google.maps.event.addListener(drawingManager, 'circlecomplete', circle => {
        //         if (this.myCircle) {
        //             this.myCircle.setMap(null);
        //         }
        //         this.myCircle = circle;
        //     });
        // });
    },

    methods: {
        getClientAddressIcon(status) {
            const icons = {
                default:      'images/marker_client_address.png',
                ongoing:      'images/marker_client_address_ongoing.png',
                completed:    'images/marker_client_address_completed.png',
                not_finished: 'images/marker_client_address_not_finished.png',
                future_work:  'images/marker_client_address_future_work.png',
            };

            if (!status) {
                return icons.default;
            }
            return icons[status];
        },
        selectedPOIId(id) {
            this.markersPOI.find(item => item.id === id).open = !this.markersPOI.find(item => item.id === id).open;
        },
        getProperIcon(marker) {
            return 'images/marker_poi_2.png';
            return marker.icon;
        },

        toggleInfoWindow(marker) {
            marker.infoWindow.opened = true;
        },

        getMapLatLng(position) {
            return {
                lat: Number(position.lat),
                lng: Number(position.lng),
            };
        },

        checkIfItsInBound(marker) {
            if (!this.myCircle || this.myCircle.visible === false) {
                return true;
            }

            const myLatLng = new window.google.maps.LatLng({ lat: Number(marker.lat), lng: Number(marker.lng) });
            const myLatLng2 = this.myCircle.getCenter();

            if (gmapApi().maps.geometry.spherical.computeDistanceBetween(myLatLng, myLatLng2) <= this.myCircle.getRadius()) {
                return true;
            }
            return false;
        },
    },
};
</script>
<style>
.gm-style-iw {
    min-width: 300px !important;
    padding: 20px !important;
    font-size: 16px !important;

}
.gm-style-iw button {
    top: 1px !important;
    right: 1px !important;
    border-radius: 8px !important;
    transition: 250ms ease all;
}

.gm-style-iw button:hover {
    background-color: #ddd !important;
}
</style>
