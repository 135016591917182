<template>
    <div class="card card-f-h flex">
        <ModalAddPOI
            :visible="modalAddPOIVisible"
            @refresh="refreshAllData"
            @close="modalAddPOIVisible = false"
        />
        <div class="w-full">
            <div class="flex justify-between border-b border-gray-300 mb-3 pb-3">
                <div class="flex">
                    <el-button :type="showClients ? 'primary' : 'info'" @click="toggleClients">
                        {{ $t('map.clients') }}
                    </el-button>
                    <el-button :type="showEmployees ? 'primary' : 'info'" @click="toggleEmployees">
                        {{ $t('map.employees') }}
                    </el-button>
                    <el-button :type="showLiveEmployees ? 'primary' : 'info'" @click="toggleLiveEmployees">
                        {{ $t('map.employees_checked') }}
                    </el-button>
                    <el-button :type="showPOI ? 'primary' : 'info'" @click="togglePOI">
                        POI
                    </el-button>
                </div>
                <div v-if="userIsBuildingCompany()" class="flex items-center">
                    <el-select v-model="filterByAddressStatus" class="mr-2" :placeholder="$t('map.status')" clearable @change="filterAddressesByStatus">
                        <el-option value="ongoing" :label="$t('map.ongoing')" />
                        <el-option value="completed" :label="$t('map.completed')" />
                        <el-option value="not_finished" :label="$t('map.not_finished')" />
                        <el-option value="future_work" :label="$t('map.future_work')" />
                    </el-select>
                    <el-popover
                        placement="bottom-end"
                        :title="$t('clients.legend')"
                        width="200"
                        trigger="hover"
                    >
                        <div slot="reference">
                            <fa-icon :icon="['fas', 'info']" fixed-width class="text-xl text-gray-400" />
                        </div>
                        <div>
                            <div class="flex items-center mb-2">
                                <img src="images/marker_client_address_ongoing.png" class="mr-4">
                                <p>{{ $t('clients.ongoing') }}</p>
                            </div>
                            <div class="flex items-center mb-2">
                                <img src="images/marker_client_address_completed.png" class="mr-4">
                                <p>{{ $t('clients.completed') }}</p>
                            </div>
                            <div class="flex items-center mb-2">
                                <img src="images/marker_client_address_not_finished.png" class="mr-4">
                                <p>{{ $t('clients.not_finished') }}</p>
                            </div>
                            <div class="flex items-center mb-2">
                                <img src="images/marker_client_address_future_work.png" class="mr-4">
                                <p>{{ $t('clients.future_work') }}</p>
                            </div>
                        </div>
                    </el-popover>
                </div>
            </div>
            <Map
                ref="mapContainer"
                :markers="markersData"
                :markersPOI="poiData"
                :markersClientsAddresses="clientsAddressesData"
                :selectedPOIId="selectedPOIId"
                style="height: calc(100vh - 175px)"
            />
        </div>
        <div v-if="showPOI" class="flex w-96 border-l ml-3 pl-3">
            <POI ref="poiComponent" @addNew="showAddPOIModal" @poiLoaded="poiLoaded" @showMarkerInfoDialog="showMarkerInfoDialog" />
        </div>
    </div>
</template>
<script>
import Map from './components/Map.vue';
// https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/javascript/examples/streetview-simple
// https://github.com/manuelbieh/geolib

export default {
    components: {
        Map,
        POI:         () => import(/* webpackChunkName: "GeneralMap/GeneralMapPOI" */ './GeneralMapPOI'),
        ModalAddPOI: () => import(/* webpackChunkName: "GeneralMap/ModalAddPOI" */ './modals/ModalAddPOI'),
    },

    data() {
        return {
            showClients:                 true,
            showEmployees:               true,
            showLiveEmployees:           true,
            showPOI:                     true,
            clientsAddressesData:        [],
            clientsAddressesInitialData: [],
            employeesData:               [],
            employeesInitialData:        [],
            employeesLiveData:           [],
            employeesLiveInitialData:    [],
            modalAddPOIVisible:          false,
            poiData:                     [],
            poiInitialData:              [],
            selectedPOIId:               null,
            filterByAddressStatus:       null,
        };
    },

    computed: {
        markersData() {
            return [...this.employeesData, ...this.employeesLiveData];
        },
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('map.map'));
        this.$store.commit('setActiveMenuItem', '0');

        this.getAllMarkers();
    },

    methods: {
        async getAllMarkers() {
            const clientsAddressesData = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/addresses/addresses_to_map`);
            const employeesData = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/addresses_to_map`);
            const employeesLiveData = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/services/service_history/currently_working_employees_to_map`);
            this.clientsAddressesData = clientsAddressesData.data;
            this.clientsAddressesInitialData = Object.freeze(clientsAddressesData.data);
            this.employeesData = employeesData.data;
            this.employeesInitialData = Object.freeze(employeesData.data);
            this.employeesLiveData = employeesLiveData.data;
            this.employeesLiveInitialData = Object.freeze(employeesLiveData.data);
        },

        poiLoaded(poiData) {
            this.poiData = poiData;
            this.poiInitialData = poiData;
        },

        toggleClients() {
            this.showClients = !this.showClients;
            this.clientsAddressesData = this.showClients ? this.clientsAddressesInitialData : [];
        },

        filterAddressesByStatus() {
            if (!this.filterByAddressStatus) {
                this.clientsAddressesData = this.clientsAddressesInitialData;
                return;
            }
            this.clientsAddressesData = this.clientsAddressesInitialData.filter(item => item.status === this.filterByAddressStatus);
        },

        toggleEmployees() {
            this.showEmployees = !this.showEmployees;
            this.employeesData = this.showEmployees ? this.employeesInitialData : [];
        },

        toggleLiveEmployees() {
            this.showLiveEmployees = !this.showLiveEmployees;
            this.employeesLiveData = this.showLiveEmployees ? this.employeesLiveInitialData : [];
        },

        togglePOI() {
            this.showPOI = !this.showPOI;
            this.poiData = this.showPOI ? this.poiInitialData : [];
        },

        showAddPOIModal() {
            this.modalAddPOIVisible = true;
        },

        refreshAllData() {
            this.getAllMarkers();
            this.$refs.poiComponent.getAll();
        },

        showMarkerInfoDialog(id) {
            this.selectedPOIId = id;
            this.$refs.mapContainer.selectedPOIId(id);
        },
    },
};
</script>
